import React from "react"
import { Link } from "gatsby"
//import styled from 'styled-components';
import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo"
import { useSpring, animated , config } from 'react-spring';
import "../css/index.css"
import "../css/layout.css"

// const HeroText = styled.div`
//     height: 100vh;
//     max-width: 50vw;
//     margin: 0px auto;
    
//     display: flex;
//     align-items: center;
//     justify-content:center;
//     flex-direction: column;
// `;



const IndexPage = () => {
 
  const fadeIn = useSpring({
    from: {
        color: '#000',
        opacity: 0, 
              
    },
    to: {
        color: '#01FF70',
        opacity: 1,
        
    },
    delay: 500,
    config: config.gentle
  });

  return(
  <Layout>
    <SEO title="Home" keywords={[`developer`, `application`, `react`, `designer`, `Victoria`, `British Columbia`]} />
   
    <animated.div style={fadeIn} className="flex items-center h-screen w-full">

      <div className="bg-black rounded shadow-lg p-8 m-4  sm:w-full md:max-w-3/4 lg:max-w-1/2 xl:w-1/2 mx-auto  ">

          <animated.h1 style={fadeIn} className="text-5xl leading-tight tracking-tighter">Marc Trudel is a full-stack developer and designer in Victoria, British Columbia, Canada.</animated.h1>
          <animated.p style={fadeIn} className="text-base tracking-tight mb-6" >He usually spends his time trying to figure out what the heck he's doing, and playing around with code.</animated.p>
          
          <Link to="/contact" className="flex flex-row-reverse">
         
            <button class="bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded inline-flex items-center">
              
              <span>Contact</span>
              <svg className="stroke-current w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>
              {/* <svg class="fill-current w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
             */}
            </button>
         
          </Link>
        
      </div>

    </animated.div>

   
   
  </Layout>
  )

}


export default IndexPage
